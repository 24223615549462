<template>
  <v-card max-width="200" outlined class="mx-auto mt-4">
    <p class="text-h5 pt-2 ma-0  d-flex justify-center">{{ title }}</p>
    <v-card-text>
      <v-avatar class="profile" color="blue" size="164" @click="redirect(to)">
        <font-awesome-icon :style="{ color: 'white' }" size="6x" :icon="ico" />
      </v-avatar>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    ico: { type: String, required: true },
	to: { type: Object, required: true },
  },
  methods: {
    redirect(to) {
      this.$router.push(to);
    },
  },
};
</script>
