<template>
  <v-row>
    <menu-item
      v-for="(item, i) in items"
      :key="i"
      v-show="hasPermission(item.permission)"
      :ico="item.ico"
      :title="item.title"
      :to="item.to"
    ></menu-item>
  </v-row>
</template>

<script>
import MenuItem from "@/components/base/MenuItem";

export default {
  components: {
    MenuItem,
  },
  props: {
    items: { type: Array, required: true },
  },
  data: () => ({
    permissions: [],
  }),
  mounted() {
    this.permissions = this.$store.state.auth.user.permissions;
  },
  methods: {
    hasPermission(permission) {
      return this.permissions.includes(permission) || permission === "any"
        ? true
        : false;
    },
  },
};
</script>
